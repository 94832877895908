@import "bootstrap/scss/bootstrap";
@import "../assets/css/cloud_tag";
@import "../assets/css/gallery";
@import "../assets/css/video";

.container-fluid {
  .content {
    min-height: 80vh;
  }
}


.about-me {
  img {
    @extend .img-fluid;
    float: left;
    max-height: 225px;
    margin: 0.4rem;
  }
}

.font-small {
  font-size: 0.9rem;
}

.footer-copyright {
  text-align: center;
  font-size: 0.7rem;
}

.page-footer {
  a {
    @extend .text-white;
  }
}

.card {
  img {
    object-fit: cover;
    max-height: 500px;
  }
}

.post-link {

  a.post-link {
    text-decoration: none;
  }

  .badge {
    a {
      @extend .text-white;
    }
  }

  .card-body {
    p {
      overflow: hidden;
      position: relative;
      line-height: 1.2em;
      max-height: 6em;
      text-align: justify;
      margin-right: -1em;
      padding-right: 1em;
    }

    p:before {
      content: '...';
      position: absolute;
      right: 0;
      bottom: 0;
    }

    p:after {
      content: '';
      position: absolute;
      right: 0;
      width: 1em;
      height: 1em;
      margin-top: 0.2em;
    }
  }
}