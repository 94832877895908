// Cloud tag font size
.set-1 {
  font-size: 1em;
}

.set-2 {
  font-size: 1.25em;
}

.set-3 {
  font-size: 1.5em;
}

.set-4 {
  font-size: 1.75em;
}

.set-5 {
  font-size: 2em;
}