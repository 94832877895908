/* responsive videos */
.video {
  figure {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    margin: 0;
  }

  iframe, object, embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .name {
    font-size: 0.75rem;
    max-height: 1.5rem;
    padding: 0.2rem;
    border-bottom: $border-color solid 1px;
    border-left: $border-color solid 1px;
    border-right: $border-color solid 1px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}